/* Mixins */
.work-order-flow-design-component .page-body-margin {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 85vh;
  padding: 30px 10px;
}
.work-order-flow-design-component .page-body-margin .content {
  height: 95%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.work-order-flow-design-component .page-body-margin .content .content-left {
  width: 70%;
  border: 1px solid #CDCDCD;
}
.work-order-flow-design-component .page-body-margin .content .content-right {
  width: 30%;
  border: 1px solid #CDCDCD;
  border-width: 1px 1px 1px 0px;
}
.work-order-flow-design-component .page-body-margin .margin-right-18 {
  margin-right: 18px;
}
.work-order-flow-design-component .page-body-margin .bottom-btn {
  margin-right: 18px;
  background-color: #2393cd;
  color: #ffffff;
  height: 32px;
  padding: 0 15px;
  line-height: 32px;
  border-radius: 4px;
}